import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { Navbar, Section } from '../components/shared'
import Footer from '../components/pages/Home/Footer'

import styles from '../assets/css/pages/static.module.scss'

const Privacy = () => {
  return (
    <React.Fragment>
      <Helmet title="Meerkat - Privacy Policy" />
      <Navbar />
      <Section>
        <h1>
          {' '}
          Meerkat Cryptocurrency Wallet Tracker App by Salt Privacy Policy
        </h1>
        <h2>Last Updated: December 19, 2018</h2>

        <p className={styles.p}>
          Salt Lending Holdings, Inc. (<b>“SALT”, “we”, “us” or “our”</b>)
          respects your privacy. This Privacy Policy describes the types of
          information we may collect from you use when you use the SALT’s
          Meerkat Cryptocurrency Wallet Tracker app that links to this Privacy
          Policy (collectively, the <b>“Site”</b>), and our practices for
          collecting, using and disclosing that information. This Privacy Policy
          does not cover any Personal Information that we may receive about you
          through sources other than the Site.
        </p>
        <p className={styles.p}>
          We may revise and update this Privacy Policy from time to time and
          will post the updated Privacy Policy to our Site. We encourage you to
          periodically review this page for the latest information on our
          privacy practices. If changes to the Privacy Policy are material
          (i.e., changes we believe you will find important), we will provide
          notice to you by e-mail or by posting notice of the changes on the
          Site. UNLESS OTHERWISE INDICATED, ANY CHANGES TO THIS PRIVACY POLICY
          WILL APPLY IMMEDIATELY UPON POSTING TO THE SITE.{' '}
        </p>
        <ol className={styles.ol}>
          <li>
            <b>Information We Collect About You and How We Collect It</b>
            <p>
              We collect information through our Site in the following ways:
            </p>
            <ul>
              <li>
                {' '}
                Directly from you when you provide it to us (described in more
                detail in Section 2).{' '}
              </li>
              <li>
                Automatically as you navigate through the Site. Information
                collected automatically may include usage details, IP addresses,
                and information collected through cookies and other tracking
                technologies (described in more detail in Section 3).{' '}
              </li>
              <li>From public blockchain ledgers. </li>
              <li>
                From third parties, for example, our business partners who
                perform services on our behalf.{' '}
              </li>
            </ul>
            <p>
              We collect several types of information from and about users of
              our Site, including Personal Information. The legal definition of
              “Personal Information” varies from jurisdiction to jurisdiction.
              Under this Privacy Policy, the term <b>“Personal Information”</b>{' '}
              refers to information that, either alone or in combination with
              other information, identifies you.{' '}
            </p>
          </li>
          <li>
            <b>Types of Information You Provide to Us</b>
            <p>
              {' '}
              Examples of the types of Personal Information that you may provide
              us on our Site (e.g., by submitting this information to us via a
              form) include:
            </p>
            <ul>
              <li>
                <b>Sign Up Information: </b> Email and name.
              </li>
              <li>
                <b>Account Information: </b> Information that you provide about
                yourself if you create an account, such as your name and e-mail
                address. You may also provide us with information about any
                crypto currency wallets that you would like to track via the
                Site.
              </li>
              <li>
                <b>Purchase and Payment Information: </b> To the extent a paid
                account is available and you sign up, details of transactions
                you carry out through our Site and of the fulfillment of your
                orders.{' '}
              </li>
              <li>
                <b>Correspondence: </b> Records and copies of your
                correspondence (including e-mail addresses), if you contact us.
              </li>
              <li>
                <b>Survey Responses: </b> Your responses to surveys that we
                might ask you to complete for research purposes.
              </li>
            </ul>
          </li>
          <li>
            <b>
              Information We Collect Through Automatic Data Collection
              Technologies, Including Cookies
            </b>
            <p>
              As you navigate through and interact with our Site, we may use
              automatic data collection technologies to collect certain
              information about your device, browsing actions and patterns,
              including:
            </p>
            <ul>
              <li>
                Details of your visits to our Site, including traffic data,
                location data, logs, other communications data and the resources
                that you access and use on our Site.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system and browser type.
              </li>
            </ul>
            <p>
              We also may use these technologies to collect information about
              your online activities over time and across third-party websites
              or other online services.
            </p>
            <p>
              The information we collect automatically (which may include
              Personal Information) is converted into statistical data, but we
              may maintain it or associate it with Personal Information we
              collect in other ways or receive from third parties. This
              information helps us to improve our Site and to deliver a better
              and more personalized service, including by enabling us to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns. </li>
              <li>
                Store information about your preferences, allowing us to
                customize our Site and marketing messages according to your
                individual interests.{' '}
              </li>
              <li>Speed up your searches. </li>
              <li>Recognize you when you return to our Site. </li>
            </ul>
            <p>
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ul>
              <li>
                <b>Cookies (or browser cookies)</b>. A cookie is a small file
                placed on the hard drive of your computer. You may refuse to
                accept browser cookies by activating the appropriate setting on
                your browser. However, if you select this setting you may be
                unable to access certain parts of our Site. Unless you have
                adjusted your browser setting so that it will refuse cookies,
                our system will issue cookies when you direct your browser to
                our Site.
              </li>
              <li>
                <b>Flash Cookies</b>. Certain features of our Site may use local
                stored objects (or Flash cookies) to collect and store
                information about your preferences and navigation to, from and
                on our Site. Flash cookies are not managed by the same browser
                settings as are used for browser cookies. For information about
                managing your privacy and security settings for Flash cookies,
                see Section 7.
              </li>
              <li>
                <b>Web Beacons</b>. Pages of our Site and our e-mails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs. pixel tags and single-pixel gifs)
                that permit us, for example, to count users who have visited
                those pages or opened an e-mail and for other related website
                statistics (for example, recording the popularity of certain
                website content and verifying system and server integrity).
              </li>
              <li>
                <b>FullStory:</b> SALT utilizes FullStory, a third-party SaaS
                analytics service (FullStory), to help enhance your experience
                when using the SALT website and platform. FullStory allows SALT
                to watch a video playback of user sessions on our platform,
                enabling meaningful insight into your experience, as an
                effective way to identify usability problems and other areas for
                improvement. Information about FullStory can be found at
                www.fullstory.com. FullStory ensures that your data is securely
                stored and accessible only to SALT, although SALT has configured
                the use of FullStory to exclude any personal identifiable
                information, except for an email address. However, if despite
                the benefits and improvements that FullStory helps SALT to
                implement, you wish to prevent SALT from using FullStory to
                record activity, you can opt-out of FullStory by visiting
                https://www.fullstory.com/optout/#. Opting out will create a
                cookie that tells FullStory to turn off recording. The presence
                of this cookie is required to continue opting out, which means
                if you clear your browser cookies, you will have to opt-out
                again.
              </li>
            </ul>
          </li>
          <li>
            <b>Third-Party Use of Cookies and Other Tracking Technologies</b>
            <p>
              Some content or applications, including advertisements and social
              media features (e.g., a “Like” button), on our Site are served by
              third-parties, including advertisers, ad networks and servers,
              content providers and application providers. These third parties
              may use cookies alone or in conjunction with web beacons or other
              tracking technologies to collect information about you when you
              use our website. The information they collect may be associated
              with Personal Information or they may collect information,
              including Personal Information, about your online activities over
              time and across different websites and other online services. They
              may use this information to provide you with interest-based
              (behavioral) advertising or other targeted content.
            </p>
            <p>
              We do not control these third parties’ tracking technologies or
              how they may be used. If you have any questions about an
              advertisement or other targeted content, you should contact the
              responsible third-party provider directly. For information about
              how you can opt out of receiving targeted advertising from many
              providers, see Section 7.
            </p>
          </li>
          <li>
            <b>How We Use Personal Information</b>
            <p>
              We use information that we collect about you or that you provide
              to us, including any Personal Information:We use information that
              we collect about you or that you provide to us, including any
              Personal Information:
            </p>
            <ul>
              <li> To present our Site and its contents to you. </li>
              <li>
                To provide you with information, products or services that you
                request from us, including by email.{' '}
              </li>
              <li>
                To provide you with notices about your account or use of our
                Site.{' '}
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.{' '}
              </li>
              <li>
                To notify you about changes to our Site or any products or
                services we offer or provide though it.{' '}
              </li>
              <li>
                To allow you to participate in interactive features on our Site.{' '}
              </li>
              <li>
                To protect against fraud and abuse (including through the use of
                automated fraud detection technology implemented by SALT or its
                third-party service providers).{' '}
              </li>
              <li>
                In any other way we may describe when you provide the
                information.{' '}
              </li>
              <li>For any other purpose with your consent. </li>
            </ul>
            <p>
              We may use the information we have collected from you to enable us
              to display advertisements to our advertisers’ target audiences.
              Even though we do not disclose Personal Information about you for
              these purposes without your consent, if you click on or otherwise
              interact with an advertisement, the advertiser may assume that you
              meet its target criteria.
            </p>
          </li>
          <li>
            <b>Disclosure of Personal Information</b>
            <p>
              We may disclose Personal Information that we collect or you
              provide as described in this Privacy Policy:
            </p>
            <ul>
              <li> To our subsidiaries and affiliates. </li>
              <li>
                To Google Analytics, an analytics service, to help us analyze
                the traffic on our Site. For more information on Google
                Analytics’ processing of Personal Information, please see “How
                Google uses data when you use our partners' sites or apps.”{' '}
              </li>
              <li>
                To advertising providers we use to help promote our products on
                our Site and on third-party websites.{' '}
              </li>
              <li>
                To our contractors, service providers and other third parties
                (e.g., payment processors) that we use to support our business
                and who are bound by contractual obligations to keep Personal
                Information confidential and use it only for the purposes for
                which we disclose it to them.{' '}
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution or other
                sale or transfer of some or all of our assets, whether as a
                going concern or as part of bankruptcy, liquidation or similar
                proceeding, in which Personal Information held by SALT about our
                Site users is among the assets transferred.{' '}
              </li>
              <li>To fulfill the purpose for which you provide it. </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.{' '}
              </li>
              <li>With your consent. </li>
            </ul>
            <p>We may also disclose Personal Information:</p>
            <ul>
              <li>
                {' '}
                To comply with any court order, law or legal or regulatory
                process.{' '}
              </li>
              <li>
                To enforce or apply our Terms of Use and other agreements,
                including for billing and collection purposes. <Link to="/terms">Terms of Use</Link>
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or the safety of SALT, our customers, or
                others.{' '}
              </li>
            </ul>
            <p>
              We also may disclose aggregated information about our users, and
              other information that does not identify any individual, without
              restriction.
            </p>
          </li>
          <li>
            <b>Choices About How We Use and Disclose Personal Information</b>
            <p>
              We strive to provide you with choices regarding the Personal
              Information you provide to us. Here are some ways that you may
              control the information we collect about or from you on our Site:
            </p>
            <ul>
              <li>
                Tracking Technologies and Advertising. You can set your browser
                to refuse all or some browser cookies, or to alert you when
                cookies are being sent. If you disable or refuse cookies, please
                note that some parts of our Site may then be inaccessible or not
                function properly.
              </li>
            </ul>
            <p>
              Although there may be other ways for you to opt out of certain
              tracking and advertising activities than those listed above, we
              currently do not respond or take any action with respect to web
              browser "do not track" signals.
            </p>
          </li>
          <li>
            <b>Your California Privacy Rights</b>
            <p>
              California law permits users who are residents of California to
              request the following information regarding our disclosure of your
              Personal Information to third parties for those third parties’
              direct marketing purposes (i) a list of certain categories of
              Personal Information that we have disclosed to certain third
              parties for their direct marketing purposes during the immediately
              preceding calendar year, (ii) the identity of certain third
              parties that received Personal Information from us for their
              direct marketing purposes during that calendar year, and (iii)
              examples of the products or services marketed (if we have that
              information). If you are a California resident and would like to
              make such a request, please e-mail us at support@meerkat.watch.
            </p>
          </li>
          <li>
            <b>Additional Rights Relating to Personal Information</b>
            <p>
              If you would like to access, amend, delete, export or restrict the
              processing of Personal Information collected via our Site, you may
              submit a request to support@meerkat.watch. We will respond to
              requests in accordance with applicable law. We cannot delete your
              Personal Information except by also deleting your user account. We
              may not accommodate a request to change information if we believe
              the change would violate any law or legal requirement or cause the
              information to be incorrect.
            </p>
            <p>
              Depending on where you live, you may have a right to lodge a
              complaint with a regulatory agency if you believe that we have
              violated any of the rights concerning Personal Information about
              you. We encourage you to first reach out to us at
              support@meerkat.watch, so we have an opportunity to address your
              concerns directly before you do so.
            </p>
          </li>
          <li>
            <b>Data Security</b>
            <p>
              We have implemented measures designed to secure Personal
              Information from accidental loss and from unauthorized access,
              use, alteration and disclosure. Unfortunately, the transmission of
              information via the internet is not completely secure. Although we
              do our best to protect Personal Information, we cannot guarantee
              the security of Personal Information transmitted to our Site. Any
              transmission of Personal Information is at your own risk. We are
              not responsible for circumvention of any privacy settings or
              security measures contained on our Site.
            </p>
            <p>
              The safety and security of Personal Information also depends on
              you. You are responsible for keeping any of your account log-in
              credentials confidential. We ask you not to share your password
              with anyone. We urge you to be careful about giving out
              information in public areas of our Site because such information
              may be viewed by any user of our Site.
            </p>
          </li>
          <li>
            <b>Children</b>
            <p>
              We do not knowingly collect Personal Information from persons
              under 13. If we learn that we have collected or received Personal
              Information from a person under the age of 13, we will delete that
              information. If you believe we might have any information from or
              about a person under 13, please contact us at
              support@meerkat.watch. Please also note that if you are under the
              age of majority in your place of residence, you are not permitted
              to use this Site or any of its features.
            </p>
          </li>
          <li>
            <b>Retention of Personal Information</b>
            <p>
              We will retain your Personal Information for the period necessary
              to fulfill the purposes for which your Personal Information has
              been collected as outlined in this Privacy Policy, unless a longer
              retention period is required by law.
            </p>
          </li>
          <li>
            <b>
              Personal Information Transferred from Outside the United States
            </b>
            <p>
              The Site is operated from, and all Personal Information collected
              via the Site will be stored and processed in, the United States.
              Although we take steps to provide adequate safeguards, the data
              protection laws of the United States might not be as comprehensive
              as those in your country. By using the Site, you consent to
              Personal Information about you being transferred to the United
              States for the purposes described in this Privacy Policy.
            </p>
          </li>
          <li>
            <b>Contact Information</b>
            <p>
              To ask questions or comment about this Privacy Policy and our
              privacy practices, contact us at support@meerkat.watch.
            </p>
          </li>
        </ol>
      </Section>
      <Footer />
    </React.Fragment>
  )
}

export default Privacy
